/* global google */
import "lazysizes";

$().ready(function(){
	var html = document.getElementsByTagName("html")[0];
	if(html){
		html.classList.remove("no-js");
		html.classList.add("js");
	}
	setupDateinReferralForm();
	setupSlideMenu();
	var map_shields = [...document.getElementsByClassName("map-click")];
	if (map_shields){
		map_shields.forEach(function(shield){
			shield.click(function(){
				this.style.display = "none";
			});
		});
	}
	if (document.querySelectorAll(".banners .banner")){
		swapBanners();
	}
	if (document.querySelectorAll("div.scroller .item")){
		scrollTestimonials();
	}

	// accessibility modes
	if (document.getElementById("high-contrast"))
		document.getElementById("high-contrast").addEventListener("click", function(){
			var li = this.closest("li");
			li.classList.toggle("active");
			checkCookies(li, "high-contrast");
		});
	if (document.getElementById("dyslexic-font"))
		document.getElementById("dyslexic-font").addEventListener("click", function(){
			var li = this.closest("li");
			li.classList.toggle("active");
			checkCookies(li, "dyslexic-font");
		});
	if (document.getElementById("text-larger"))
		document.getElementById("text-larger").addEventListener("click", function(){
			var li = this.closest("li");
			li.classList.add("active");
			var body_classes = document.body.classList;
			if (body_classes.contains("font-size-2") || body_classes.contains("font-size-3")){
			//get the matching class and increase it
				for(var i=0; i < body_classes.length; i++){
					var c = body_classes.item(i);
					if(c.includes("font-size-")){
						var last = parseInt(c.slice(-1)) + 1;
						var new_c = c.slice(0,-1) + last.toString();
						checkCookies(false, c); // out with the old
						checkCookies(li, new_c);
					}
				}
			} else {
				checkCookies(li, "font-size-2");
			}
		});
	if (document.getElementById("text-smaller"))
		document.getElementById("text-smaller").addEventListener("click", function(){
			var li = this.closest("li");
			li.classList.remove("active");
			checkCookies(li, "font-size-4");
		});

	// program postcode search
	var pc_forms = Array.from(document.getElementsByClassName("generate-action"));
	pc_forms.forEach(function(pc_form){
		if (pc_form){
			pc_form.addEventListener("submit", function(e){
				e.preventDefault();
				var data_url = pc_form.dataset.target;
				var pc_value = document.getElementById("postcode-search-"+pc_form.dataset.id).value;
				var program_route = data_url.replace("xxxx", pc_value);
				// load route
				window.location.href = program_route;
			});
		}
	});
	if (document.querySelectorAll(".region-trigger")){
		toggleRegions();
	}

	//referral form
	var optional_fieldsets = [...document.querySelectorAll("fieldset.optional")];
	if (optional_fieldsets.length > 0){
		toggleFieldsets(optional_fieldsets);
	}
	//add/remove child fieldset(s)
	var child_section = document.querySelectorAll("fieldset.referral-child");
	if (child_section.length > 0){
		dynamicChildSections();
	}

	// content tables
	responsiveTables();
});

var delay;
window.addEventListener("resize", function(){
	clearTimeout(delay);
	delay = setTimeout(function(){responsiveTables();}, 100);
});

export function setupDateinReferralForm(){
	var date_field = document.getElementById("date-today");
	if (date_field){
		// Format the date as "DD-MM-YYYY"
		var today = new Date().toLocaleDateString("en-AU");
		date_field.value = today;
	}
	
}

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function swapBanners(){
	if (document.querySelectorAll(".banners .banner").length > 1){
		// only one in site currently
		var wrap = document.getElementsByClassName("banner-stage")[0];
		var nav = document.getElementsByClassName("banner-navigation")[0];
		var nav_wrap = document.getElementsByClassName("banner-navigation-body")[0];
		nav.style.display = "block";		

		var swap = function swap(){
			// banners
			var current_banner = document.querySelectorAll(".banner.active")[0];
			var next_banner = (current_banner.nextElementSibling != null)? current_banner.nextElementSibling: wrap.firstElementChild;
			current_banner.classList.remove("active");
			next_banner.classList.add("active");

			// nav
			var current_nav = document.querySelectorAll(".banner-nav-item.active")[0];
			var next_nav = (current_nav.nextElementSibling != null)? current_nav.nextElementSibling: nav_wrap.firstElementChild;
			current_nav.classList.remove("active");
			next_nav.classList.add("active");
		};
		setInterval(swap, 5000);
	}
}

export function scrollTestimonials(){
	var items = document.querySelectorAll(".scroller .item");
	if (items.length > 1){
		// only one in site currently
		var wrap = document.getElementsByClassName("scroll-wrap")[0];
		var scroll = document.getElementsByClassName("scroller")[0];
		var nav = document.getElementsByClassName("scroll-nav")[0];
		nav.style.display = "block";

		if (scroll.scrollWidth > wrap.clientWidth){
			if(!scroll.getAttribute("data-animating")){
				setTimeout(slide, 5000);
			}
		} else if (scroll.getAttribute("data-animating")){
			scroll.classList.remove("slide");
			scroll.style.transform = "translateX(0px)";
			scroll.setAttribute("data-animating", false);
		}
		var slide = function slide(){
			scroll.setAttribute("data-animating", true);
			scroll.classList.add("slide");

			//listen
			scroll.addEventListener("webkitTransitionEnd", reset);
			scroll.addEventListener("oTransitionEnd", reset);
			scroll.addEventListener("transitionend", reset);

			var current = scroll.children[0];
			var distance = current.clientWidth;
			var next = scroll.children[1];

			scroll.style.transform = "translateX(-"+distance+"px)";

			// set active
			current.classList.remove("active");
			next.classList.add("active");
			var id = next.getAttribute("id");
			[...nav.children].forEach(function(n){
				if (n.getAttribute("data-id") != id){
					n.classList.remove("active");
				}
				else n.classList.add("active");
			});
		};
		var reset = function reset(){
			scroll.classList.remove("slide");
			//ignore the next transition
			scroll.removeEventListener("webkitTransitionEnd", reset, false);
			scroll.removeEventListener("oTransitionEnd", reset, false);
			scroll.removeEventListener("transitionend", reset, false);

			scroll.appendChild(scroll.children[0]);
			scroll.style.transform = "translateX(0px)";
			setTimeout(slide, 5000);
		};
		/* ToDo: add prev + next clickers function */
	}
}

export function responsiveTables(){
	var content_tables = [...document.querySelectorAll("#content .page-body table")];
	if (content_tables.length > 0 ){
		content_tables.forEach(function(table){
			var wrap_div;
			if (table.offsetWidth > table.closest(".contentwrap").offsetWidth){
				if (!table.parentNode.classList.contains("table-wrap")){
					wrap_div = document.createElement("div");
					wrap_div.classList.add("table-wrap");
					wrap(table, wrap_div);
				}
			} else {
				wrap_div = table.closest("div.table-wrap");
				if (wrap_div) unwrap(wrap_div);
			}
		});
	}
	function wrap(el, wrapper){
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
	}
	function unwrap(wrapper){
		var parent = wrapper.parentNode;
		// move all children of wrapper to the parent
		while(wrapper.firstChild){
			parent.insertBefore(wrapper.firstChild, wrapper);
		}
		// remove empty wrapper
		parent.removeChild(wrapper);
	}
}

/**** ======== Accessibility cookie functions ======== ****/
export function checkCookies(el, classname){
	if(navigator.cookieEnabled){
		// check if el has a classList, else it is true/false already
		var mode = (el.classList)? el.classList.contains("active"): el;
		setCookie(classname, mode);
	} else {
		// do it this page
		document.body.classList.toggle(classname);
	}
}
export function setCookie(cname, cvalue){
	// some browsers won't delete the cookie without a path
	document.cookie = cname +"="+ cvalue +";"+"path=/";
	if (cvalue != false || cvalue != ""){
		document.body.classList.add(cname);
	} else {
		document.body.classList.remove(cname);
	}
}
// function getCookie(cname){
// 	var name = cname + "=";
// 	var ca = document.cookie.split(";");
// 	for(var i = 0; i < ca.length; i++) {
// 		var c = ca[i];
// 		while (c.charAt(0) == " ") {
// 			c = c.substring(1);
// 		}
// 		if (c.indexOf(name) == 0) {
// 			return c.substring(name.length, c.length);
// 		}
// 	}
// 	return "";
// }

/**** ======== Toggle Regions + Fieldsets  ========****/
export function toggleRegions(){
	[...document.querySelectorAll("div.region")].forEach(function(region){
		toggleWrapper(region, true);
	});
}
export function toggleFieldsets(fieldsets){
	fieldsets.forEach(function(f_set){
		toggleWrapper(f_set, false);
	});
}
export function toggleWrapper(wrapper, only_one){
	var trigger = wrapper.firstElementChild;
	var target = wrapper.lastElementChild;
	trigger.addEventListener("click", function(){
		var r_classes = wrapper.classList;
		if (r_classes.contains("active")){
			// it's going to be closed
			target.style.maxHeight = "0px";
		} else {
			// it's going to be opened
			target.style.maxHeight = setMaxHeight(target)+"px";
			if (only_one){
				//undo siblings - only one active at a time
				[...wrapper.parentElement.children].forEach(function(sib){
					if (sib != wrapper){
						sib.classList.remove("active");
						var sib_target = sib.lastElementChild;
						if(sib_target) sib_target.style.maxHeight = "0px";
					}
				});
			}
		}
		r_classes.toggle("active");
	});
}
export function setMaxHeight(expand_div){
	// get height
	var clone = expand_div.cloneNode(true);
	expand_div.after(clone);
	clone.style.maxHeight = "none";
	var clone_height = clone.clientHeight + 30;
	clone.remove();
	// set height
	return clone_height; // a bit of lee-way
}

/**** ======== Dynamic Child Fieldsets  ========****/
export function dynamicChildSections(){
	var add_div = document.getElementById("add-child");
	add_div.addEventListener("click", function(){
		var prev_child = this.previousElementSibling;
		var new_child = prev_child.cloneNode(true);
		new_child = incrementIdIndex(new_child);
		prev_child.after(new_child);

		// add remove trigger (if doesn't exist)
		var legend = new_child.firstElementChild;
		if(legend.childNodes.length == 1){
			var rm_span = "<span class='remove-me'>Remove</span>";
			legend.insertAdjacentHTML("beforeend", rm_span);
		}
		legend.lastElementChild.addEventListener("click", function(){
			var child_section = this.closest("fieldset");
			child_section.remove();
		});
		// expand the new fieldset
		new_child.classList.add("rm", "active");
		new_child.lastElementChild.style.maxHeight = setMaxHeight(new_child.lastElementChild)+"px";
		[...new_child.querySelectorAll("input")].forEach((el)=>el.value="");
		toggleWrapper(new_child, false);
	});
	function incrementIdIndex(the_child){
		[...the_child.getElementsByTagName("input")].forEach(function(input){
			var id_count = 1;
			var label = input.previousElementSibling;
			var id_arr = input.id.split("-");
			// if last place is a number we're going to update it
			if (!isNaN(id_arr[id_arr.length-1])){
				var i = parseInt(id_arr.pop());
				id_count = i+1;
			}
			// add the new index count to input and label
			var updated_id = id_arr.join("-") +"-"+ id_count.toString();
			input.id = updated_id;
			label.htmlFor = updated_id;
		});
		return the_child;
	}
}

/**** ======== Contact Maps + Tables ========****/
export class Location {
	constructor(location, map){
		if (location.latitude && location.longitude){
			// map info window
			var name = "<p class=\"name c3\">"+location.name+"</p>";
			var desc = (location.description && location.description != "")? "<p class=\"des\">"+ location.description +"</p>" : "";
			var ph = (location.contact_phone && location.contact_phone != "")? "<p class=\"ph\"><a href=\"tel:"+ location.contact_phone.replace(/ /g,"") +"\">"+ location.contact_phone +"</a></p>" : "";
			var info_html = "<div id=\""+ location.id +"\" class=\"info\">"+ name + desc + ph + "</div>";
			var info =  new google.maps.InfoWindow({content: info_html});
			// map pin
			var marker_pos = {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)};
			var marker = new google.maps.Marker({
				position: marker_pos,
				map: map,
				title: this.title,
				icon: "/app/img/pin.png"
			});
			//click pop-up info window
			marker.addListener("click", function(){
				info.open(map, marker);
			});
			//add to map
			marker.setMap(map);

			// class properties
			this.marker = marker;
		} else {
			this.marker = 0;
		}
	}
}
window.initMaps = function(){
	var maps = [];
	var tamworth = {lat: -31.092, lng: 150.923};
	var map_style = [{"stylers":[{"saturation":-100},{"gamma":1}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"saturation":50},{"gamma":0},{"hue":"#50a5d1"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text.fill","stylers":[{"color":"#333333"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"weight":0.5},{"color":"#333333"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"gamma":1},{"saturation":50}]}];
	var map_options = {zoom: 18, center: tamworth, styles: map_style};
	var cluster_options = {gridSize: 50, maxZoom: 20, styles:[{url: "/app/img/cluster.png", height: 66, width: 66, textColor: "white"}]};

	import(/* webpackChunkName: "markerclusterer_chunk" */ "./markerclusterer.js").then(mc =>{
		// get elements
		var divs = document.getElementsByClassName("map");
		// for each region
		for(var i=0; i < divs.length; i++){
			var map = new google.maps.Map(divs[i], map_options);
			maps.push(map);
			var cluster = new mc.default(map, [], cluster_options);
			var pin_bounds = new google.maps.LatLngBounds();
			var markers = [];

			var locations = JSON.parse(divs[i].dataset.locations);
			locations.forEach(function(location) {
				location.place = new Location(location, map);
				if(location.place.marker){
					markers.push(location.place.marker);
					pin_bounds.extend(location.place.marker.position); //add to bounds
				}
			});
			cluster.addMarkers(markers);
			map.fitBounds(pin_bounds);
			map.panToBounds(pin_bounds);
		}
	});
};
export function ready(fn) {
	if (document.readyState != "loading"){
		fn();
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
